import { Button, Divider, Form, InputNumber, List, Flex, Space, Input, Tabs, message, Select, Tooltip } from 'antd'
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons'
import React, { useContext, useEffect, useState } from 'react'
import { transliterate } from '../../hooks/transliterate'

import { useHttp } from '../../hooks/http.hook'
import { AuthContext } from '../../context/AuthContext'
import { DataContext } from '../../context/DataContext'


export const Settings = () => {

    const [contractTypes, setContractTypes] = useState([])
    const [formValues, setFormValues] = useState({})
    const [newRecord, setNewRecord] = useState(null)
    const { request } = useHttp()
    const { token } = useContext(AuthContext)
    const [settingsFormEmail] = Form.useForm()
    const [settingsForm] = Form.useForm()

    const { changeSettings } = useContext(DataContext)

    // const settings = {
    //     actualDays: 1,
    //     maintenancePeriod: 2000,
    // }

    const data = [
        { value: 'service', label: 'Сервисное обслуживание' },
        { value: 'installation', label: 'Монтаж оборудования' },
        { value: 'serviceBase', label: 'Рамочный сервисный договор' },
    ]

    useEffect(() => {
        const formFetch = async () => {
            try {
                const response = await request('o/api/settings', 'GET', null, {
                    Authorization: `Bearer ${token}`
                })
                if (!response) {
                    message.error('Ошибка! Сервер недоступен...')
                }
                setFormValues(response)
            } catch (error) {
                message.error('Ошибка! Сервер недоступен...')
            }

        }
        formFetch()
    }, [])

    useEffect(() => {
        // console.log('formValues effect', formValues)
        if (formValues?.contractTypes) {
            setContractTypes(formValues.contractTypes)
            settingsForm.setFieldValue('maintenancePeriod', formValues.maintenancePeriod)
            settingsForm.setFieldValue('actualDays', formValues.actualDays)
            settingsForm.setFieldValue('forecastDays', formValues.forecastDays)
            // settingsFormEmail.setFieldValue('email', formValues.email)
        }
        // else {
        //     setContractTypes(data)

        // }
    }, [formValues])


    const onFinish = () => {
        let values = { ...settingsForm.getFieldsValue(), contractTypes }
        // console.log('Received values of form: ', values);
        updateSettings(values)
    }
    const onFinishEmail = () => {
        let values = { ...settingsFormEmail.getFieldsValue(), contractTypes }
        // console.log('Received values of form: ', values);
        updateSettings(values)
    }


    const updateSettings = async (values) => {
        try {
            const response = await request('o/api/settings', 'POST', values, {
                Authorization: `Bearer ${token}`
            })
            if (!response) {
                message.error('Ошибка! Сервер недоступен...')
            }
            changeSettings(response) //save context
            message.success('Настройки сохранены!')
        } catch (error) {
            message.error('Ошибка передачи данных!')
        }
    }

    const handleInput = (e) => {
        setNewRecord({
            ...newRecord, [e.target.name]: e.target.value,
        })

    }

    const handleEnter = (e) => {
        handleInput(e)

        if (e?.target?.value) {
            setContractTypes(
                [
                    ...contractTypes,
                    { label: e.target.value, value: transliterate(e.target.value) }
                ]
            )
        }
    }

    const addContractType = () => {
        let savedTypes = [...contractTypes].filter((item) => item.value !== 'new')
        setContractTypes([...savedTypes,
        {
            value: 'new',
            label:
                <Input
                    autoFocus
                    name="label"
                    onChange={handleInput}
                    onPressEnter={handleEnter}
                    // onBlur={handleBlur}
                    placeholder='Введите тип договора'
                    style={{ width: '100%' }}
                />
        }])
    }

    const deleteContractType = (key) => {
        setContractTypes(contractTypes.filter((item, index) => index !== key))

    }

    const saveContractType = () => {

        const newContractTypes = [...contractTypes].filter((i) => i.value !== 'new')
        let newContractList = [...newContractTypes, { label: newRecord.label, value: transliterate(newRecord.label) }]
        setContractTypes(newContractList)

    }

    const items = [
        {
            key: '1',
            label: 'Основные',
            children: <>
                <Form
                    // onFinish={onFinish}
                    form={settingsForm}
                    name="settings"
                    initialValues={formValues}
                >

                    <Form.Item label="Период в днях для актуализации наработки"
                        name={"actualDays"}>
                        <InputNumber min={1} max={365} style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item label="Период в днях для планирования работ по ТО"
                        name={"forecastDays"}>
                        <InputNumber min={1} max={365} style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item label="Межсервисный интервал в моточасах (по умолчанию)"
                        name={"maintenancePeriod"}>
                        <InputNumber min={1} style={{ width: '100%' }} />
                    </Form.Item>

                    <Form.Item
                        label="Типы договоров"
                        initialValue={contractTypes}
                        name="contractTypes">

                        <List
                            size="small"
                            bordered
                            dataSource={contractTypes}
                            style={{ backgroundColor: 'white' }}
                            renderItem={(item, index) =>
                                <List.Item key={item?.value}>
                                    <Flex gap='middle' align='center'
                                        justify='space-between' style={{ width: '100%' }}
                                    >
                                        <Space>
                                            <div>{index + 1}.</div>
                                            {item?.label}
                                        </Space>
                                        <Space size={'small'}>
                                            {item.value === 'new' && <Button type='text'
                                                style={{
                                                    color: 'blue',
                                                    border: 'none',
                                                }}
                                                onClick={() => saveContractType(item)}
                                            ><SaveOutlined /></Button>}
                                            {!JSON.stringify(data).includes(item?.value) && <Button type='text'
                                                style={{
                                                    color: 'red',
                                                    border: 'none',
                                                }}
                                                onClick={() => deleteContractType(index)}
                                            ><DeleteOutlined /></Button>}
                                        </Space>
                                    </Flex>
                                </List.Item>
                            }
                            footer={<div>
                                <Button onClick={addContractType}>+ ДОБАВИТЬ</Button>
                            </div>}
                        />
                    </Form.Item>

                    <span style={{ fontWeight: 300 }}>Версия приложения: <b>1.0.4(2)</b></span><br />
                    <span style={{ fontWeight: 300 }}>Разработка: ebalyasnikov@mail.ru</span>
                    <Divider />
                    <Form.Item>
                        <Button
                            type="primary"
                            onClick={onFinish}
                            // htmlType="submit" 
                            style={{ marginTop: '2rem' }}>Сохранить</Button>
                    </Form.Item>
                </Form>
            </>,
        },
        {
            key: '2',
            label: 'Почта',
            children: <>
                <Form
                    // onFinish={onFinish}
                    form={settingsFormEmail}
                    name="settingsEmail"
                    initialValues={formValues}
                >
                    <p>Настройка уведомлений из личного кабинета клиента</p>
                    <Form.Item label="ТО" name={["email", "mntc"]}            >
                        <Select
                            mode='multiple'
                            style={{ width: '100%' }}
                            options={
                                formValues?.users?.map(item =>
                                ({
                                    key: item.email,
                                    label: item.name,
                                    value: item.email,
                                }))
                            }
                            optionRender={(option) =>
                                <Tooltip placement='top' title={option.value} color={'#052b78'}>
                                    {option.label}
                                </Tooltip>
                            }
                        />
                    </Form.Item>
                    <Form.Item label="Монтаж" name={["email", "install"]}            >
                        <Select
                            mode='multiple'
                            style={{ width: '100%' }}
                            options={
                                formValues?.users?.map(item =>
                                ({
                                    key: item.email,
                                    label: item.name,
                                    value: item.email,
                                }))
                            }
                            optionRender={(option) =>
                                <Tooltip placement='top' title={option.value} color={'#052b78'}>
                                    {option.label}
                                </Tooltip>
                            }
                        />
                    </Form.Item>
                    <Form.Item label="Испытания" name={["email", "test"]}            >
                        <Select
                            mode='multiple'
                            style={{ width: '100%' }}
                            options={
                                formValues?.users?.map(item =>
                                ({
                                    key: item.email,
                                    label: item.name,
                                    value: item.email,
                                }))
                            }
                            optionRender={(option) =>
                                <Tooltip placement='top' title={option.value} color={'#052b78'}>
                                    {option.label}
                                </Tooltip>
                            }
                        />
                    </Form.Item>
                    <Form.Item label="Обучение" name={["email", "study"]}            >
                        <Select
                            mode='multiple'
                            style={{ width: '100%' }}
                            options={
                                formValues?.users?.map(item =>
                                ({
                                    key: item.email,
                                    label: item.name,
                                    value: item.email,
                                }))
                            }
                            optionRender={(option) =>
                                <Tooltip placement='top' title={option.value} color={'#052b78'}>
                                    {option.label}
                                </Tooltip>
                            }
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button
                            type="primary"
                            onClick={onFinishEmail}
                            // htmlType="submit" 
                            style={{ marginTop: '2rem' }}>Сохранить</Button>
                    </Form.Item>
                </Form>
            </>,
        }
    ]


    return (
        <div style={{ maxWidth: '600px', width: '100%' }}>
            <h2>Настройки приложения</h2>
            <Tabs defaultActiveKey="1" items={items} >
            </Tabs>
        </div >


    )
}

// {/* <Form
//                 // onFinish={onFinish}
//                 form={settingsForm}
//                 name="settings"
//                 initialValues={formValues}
//             >
//                 <Divider />
//                 <Form.Item label="Период в днях для актуализации наработки"
//                     name={"actualDays"}>
//                     <InputNumber min={1} max={365} style={{ width: '100%' }} />
//                 </Form.Item>
//                 <Form.Item label="Период в днях для планирования работ по ТО"
//                     name={"forecastDays"}>
//                     <InputNumber min={1} max={365} style={{ width: '100%' }} />
//                 </Form.Item>
//                 <Form.Item label="Межсервисный интервал в моточасах (по умолчанию)"
//                     name={"maintenancePeriod"}>
//                     <InputNumber min={1} style={{ width: '100%' }} />
//                 </Form.Item>

//                 <Form.Item
//                     label="Типы договоров"
//                     initialValue={contractTypes}
//                     name="contractTypes">

//                     <List
//                         size="small"
//                         bordered
//                         dataSource={contractTypes}
//                         style={{ backgroundColor: 'white' }}
//                         renderItem={(item, index) =>
//                             <List.Item key={item?.value}>
//                                 <Flex gap='middle' align='center'
//                                     justify='space-between' style={{ width: '100%' }}
//                                 >
//                                     <Space>
//                                         <div>{index + 1}.</div>
//                                         {item?.label}
//                                     </Space>
//                                     <Space size={'small'}>
//                                         {item.value === 'new' && <Button type='text'
//                                             style={{
//                                                 color: 'blue',
//                                                 border: 'none',
//                                             }}
//                                             onClick={() => saveContractType(item)}
//                                         ><SaveOutlined /></Button>}
//                                         {!JSON.stringify(data).includes(item?.value) && <Button type='text'
//                                             style={{
//                                                 color: 'red',
//                                                 border: 'none',
//                                             }}
//                                             onClick={() => deleteContractType(index)}
//                                         ><DeleteOutlined /></Button>}
//                                     </Space>
//                                 </Flex>
//                             </List.Item>
//                         }
//                         footer={<div>
//                             <Button onClick={addContractType}>+ ДОБАВИТЬ</Button>
//                         </div>}
//                     />
//                 </Form.Item>
//                 <Form.Item label="email на обслуживание"
//                     name={["email", "mntc"]}

//                 >
//                     <Input />
//                 </Form.Item>

//                 <Form.Item>
//                     <Button
//                         type="primary"
//                         onClick={onFinish}
//                         // htmlType="submit"
//                         style={{ marginTop: '2rem' }}>Сохранить</Button>
//                 </Form.Item>
//             </Form> */}